<template>
  <router-link :to="destination ? destination : ''">
    <v-btn
      @click="$emit('click')"
      :icon="true"
      small
      outlined
      :color="color ? color : '#001c6c'"
      ><v-icon>{{ icon ? icon : 'mdi-plus' }}</v-icon></v-btn
    >
  </router-link>
</template>

<script>
  export default {
    name: 'ButtonShowMore',
    data() {
      return {}
    },
    props: {
      color: { type: String, required: false },
      icon: { type: String, required: false },
      destination: { type: String, required: false },
    },
    methods: {},
  }
</script>

<style lang="scss" scoped></style>
